import { useEffect, useState } from "react";
import {
  ClassificationDto,
  InteractionTypePropertyDto,
  LearningObjectiveDto,
  PublishQuestionResponse,
  PublishValidationError,
  QuestionClient,
  TemplateDto,
  ValidationDto,
} from "../../../api/learnable_question/QuestionClient";
import TextMediaContainer from "./TextMediaContainer";
import { Button } from "@progress/kendo-react-buttons";
import {
  copyQuestion,
  createQuestionDto,
  getQuestionCopy,
  getSelectedClassificationIds,
  // setMediumImageAreas,
  syncQuestionInteractionPropertiesWithTemplate,
  syncQuestionMediaWithTemplate,
  syncQuestionOptionWithTemplate,
  syncQuestionTextsWithTemplate,
  syncSequencingValuesForAllTypes,
} from "./functions/questionDtoFunctions";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import InteractionTypeProperty from "./InteractionTypeProperty";
import { appConfig } from "../../../appConfig";
import {
  fetchConfiguration,
  fetchQuestion,
  fetchValidationDto,
  publishQuestion,
  saveQuestionDto,
} from "../../../api/learnable_question/apiFunctions";
import { openQuestion, openQuestionPreview } from "./functions/helperFunctions";
import CustomDialog from "../../common/CustomDialog";
import { useSearchParams } from "react-router-dom";
import {
  iClassificationItem,
  iConfiguration,
  iConfigurationClassification,
} from "../../common/classifications/interfaces";
import { useAppContext } from "../../common/AppContext";
import WhereIsUsedDialog from "./WhereIsUsedDialog";
import { AssessmentDto, Client, WhereUsedDto } from "../../../api/Client";
import { iInfo } from "../../../interfaces/iInfo";
import Info from "../../common/Info";
import {
  FF_PUBLISH,
  // AMNE_CLASSIFICATION_ID,
  // BEHORERHET_CLASSIFICATION_ID,
  // BEHORERHET_FM_CLASSIFICATION_ID,
  // FRAGESTYP_CLASSIFICATION_ID,
  KEY_INFO_BEHORIGHET_CLASSIFICATIONS,
  KEY_INFO_COMMENT,
  KEY_INFO_CUSTOMER,
  KEY_INFO_DEPRECATED,
  KEY_INFO_EXTERNAL_ID,
  KEY_INFO_FRAGETYP_CLASSIFICATIONS,
  KEY_INFO_KNOWLEDGE_LEVEL,
  KEY_INFO_LEARNING_OBJECTIVES,
  KEY_INFO_STATUS,
  KEY_INFO_WHERE_USED_INTERACTIONS,
  KEY_INFO_WHERE_USED_LEARNINGOBJECTS,
  KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES,
  LEARNING_OBJECTIVES_ROOT_ID,
} from "../../common/Constants";

import { getCurrentPublishStatusText, getInfoCopy } from "../../common/helperFunctions";
import LoadingPanel from "../../common/LoadingPanel";
import { ExpansionPanel, ExpansionPanelActionEvent, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import PublishDialog from "../../common/PublishDialog";
import { useFeatureFlag } from "@strmediaochitab/optima-react-components";

import { Service } from "../../common/classifications/enums";
import { ConfigProvider } from "../../common/classifications/types";
import { PublishStatusType, StatusType } from "../../common/Enums";
import { isPublisher } from "../../../api/learnable_lcms/apiFunctions";
import { getWhereUsedQuestion } from "../../common/classifications/helperFunctions";
const NewQuestion = (props: any) => {
  const usePublish = useFeatureFlag(FF_PUBLISH);

  const appObject = useAppContext();
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);
  const questionApiVersion = appConfig.REACT_APP_QUESTION_API_VERSION;

  const [templateDto, setTemplateDto] = useState<TemplateDto | undefined>(undefined);
  const [interactionTypeProperties, setInteractionTypeProperties] = useState<InteractionTypePropertyDto[]>([]);
  const [templates, setTemplates] = useState<TemplateDto[] | undefined>(undefined);
  const [question, setQuestion] = useState<iQuestionDtoExtended | undefined>(undefined);
  const [validationDto, setValidationDto] = useState<ValidationDto | undefined>(undefined);
  const [showCustomDialog, setShowCustomDialog] = useState<any>(null);
  const [showWhereIsUsedDialog, setShowWhereIsUsedDialog] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [publishQuestionResponse, setPublishQuestionResponse] = useState<PublishQuestionResponse | undefined>(
    undefined
  );
  const showQuestionPanel: boolean = props.showQuestionPanel ?? false;

  const [userIsPublisher, setUserIsPublisher] = useState<boolean>(false);

  let hideMetadata: boolean = false;
  let hideToolsMenu: boolean = false;
  let hideButtons: boolean = false;
  let incomingQuestionId: number = (() => {
    if (props.id >= 0) {
      // hideMetadata = true;
      hideToolsMenu = true;
      hideButtons = true;
      return props.id;
    }

    return searchParams.get("id") ? parseInt(searchParams.get("id") as string) : 0;
  })();

  let incomingQuestionTemplateId: number =
    incomingQuestionId === 0 && searchParams.get("templateId") ? parseInt(searchParams.get("templateId") as string) : 0;

  const questionApiUrl: string = appConfig.REACT_APP_QUESTION_API_URL as string;
  const questionClient = new QuestionClient(questionApiUrl);

  const [selectedClassifications, setSelectedClassifications] = useState<iClassificationItem[]>([]);
  const [whereIsUsedAssessments, setWhereIsUsedAssessments] = useState<AssessmentDto[]>([]);

  const fetchWhereIsUsedAssements = async (contentId: string) => {
    let client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    let result = await client.assessmentGetByDataContentId(contentId, false);
    setWhereIsUsedAssessments(result);

    return result;
  };

  const fetchTemplates = async (): Promise<TemplateDto[]> => {
    var result = await questionClient.templateGetTemplates(undefined, undefined, "1");
    setTemplates(result);

    return result;
  };

  const fetchInteractionTypeProperties = async () => {
    var result = await questionClient.lookupGetInteractionTypeProperty(questionApiVersion);
    setInteractionTypeProperties(result);
    return result;
  };

  const getTemplateDataItems = async (templates: TemplateDto[], questionTemplateId: number) => {
    //console.log("fetchTemplateDataItems",templates);
    let template = templates!.filter((t) => t.id === questionTemplateId)[0];

    setTemplateDto(template);
    return template;
  };

  const [initDone, setInitDone] = useState<boolean>(false);
  const [infoData, setInfoData] = useState<iInfo | undefined>(undefined);
  const [statusChangedByUser, setStatusChangedByUser] = useState<boolean>(false);

  const init = async () => {
    setStatusChangedByUser(false);
    let userIsPublisher: boolean = await isPublisher();
    setUserIsPublisher(userIsPublisher);

    let interactionTypeProperties = await fetchInteractionTypeProperties();

    const question = await loadQuestion(incomingQuestionId, interactionTypeProperties);
    let whereUsedItems: WhereUsedDto[] | undefined = undefined;
    let configurationData: iConfiguration = await fetchConfiguration("Classifications");
    let newConfigurationData: iConfiguration = { ...configurationData };
    const newClassification: iConfigurationClassification = {
      rootIds: [LEARNING_OBJECTIVES_ROOT_ID],
      title: "Lärandemål",
      showSelected: true,
      checkChildren: false,
      key: LEARNING_OBJECTIVES_ROOT_ID,
    };
    newConfigurationData.classifications!.push(newClassification);
    newConfigurationData.learningObjectives = {
      rootId: LEARNING_OBJECTIVES_ROOT_ID,
      title: "Lärandemål",
      showSelected: true,
    };

    setConfigData(newConfigurationData);

    let selectedClassifications: iClassificationItem[] = getSelectedClassificationIds(question);
    setSelectedClassifications(selectedClassifications);

    setExpanded(incomingQuestionId === 0);

    if (question.contentId && question.contentId.length > 0) {
      await fetchWhereIsUsedAssements(question.contentId!);
    }

    if (incomingQuestionId !== 0) {
      whereUsedItems = await getWhereUsedQuestion(question);
      console.log("whereUsedItems", whereUsedItems);
    }

    //where used is only visible if question is saved
    if (question && question.id && question.id > 0) {
      visibleInfoElements.push(KEY_INFO_WHERE_USED_LEARNINGOBJECTS);
      visibleInfoElements.push(KEY_INFO_WHERE_USED_INTERACTIONS);
      visibleInfoElements.push(KEY_INFO_EXTERNAL_ID);
    }
    let info: iInfo = {
      customerId: question.customerId!,
      statusId: question!.statusId!,
      knowledgeLevel: question!.knowledgeLevel!,
      deprecated: question!.deprecated!,
      selectedItems: selectedClassifications ?? [],
      visibleElements: visibleInfoElements,
      subTypeId: 0,
      comment: question!.comment ?? "",
      whereUsed: whereUsedItems,
      externalId: question.friendlyId ?? "",
    };

    setInfoData(info);
    setInitDone(true);
  };

  const getInteractonTypePropertyIdByName = (
    question: iQuestionDtoExtended,
    interactionTypeProperties: InteractionTypePropertyDto[],
    name: string
  ) => {
    const found: InteractionTypePropertyDto | undefined = interactionTypeProperties.find(
      (x) => x.interactionTypeId == question!.interactionTypeId && x.property === name
    );
    if (found) {
      return found.id;
    }
    return undefined;
  };

  const loadQuestion = async (questionId: number, interactionTypeProperties: InteractionTypePropertyDto[]) => {
    let question: iQuestionDtoExtended | undefined = undefined;
    if (questionId === 0) {
      question = await getNewQuestion(incomingQuestionTemplateId, interactionTypeProperties);
    } else {
      question = await getQuestion(questionId, interactionTypeProperties);
    }
    console.log("loadQuestion", question);

    return question;
  };

  const getQuestion = async (questionId: number, interactionTypeProperties: InteractionTypePropertyDto[]) => {
    let question: iQuestionDtoExtended = (await fetchQuestion(questionId)) as iQuestionDtoExtended;
    let templates: TemplateDto[] = await fetchTemplates();
    let template: TemplateDto = await getTemplateDataItems(templates, question.templateId!);

    // setMediumImageAreas(question, template.template!.textAndMedia!);

    let validationDto: ValidationDto = await fetchValidationDto(question);

    setValidationDto(validationDto);
    // question.correctResponsePatternId = interactionTypeProperties.filter(
    //   (x) => x.interactionTypeId == question!.interactionTypeId && x.property === "correctResponsesPattern"
    // )[0].id!;
    question.singleChoiceId = getInteractonTypePropertyIdByName(question, interactionTypeProperties, "SingleChoice")!;
    question.correctResponsePatternId = getInteractonTypePropertyIdByName(
      question,
      interactionTypeProperties,
      "correctResponsesPattern"
    )!;
    question.interactionTypeName = appObject.interactionTypes.find((x) => x.id === question!.interactionTypeId)?.name!;

    syncQuestionWithTemplate(question, template!, interactionTypeProperties);

    setQuestion(question);
    return question;
  };

  const getNewQuestion = async (
    incomingQuestionTemplateId: number,
    interactionTypeProperties: InteractionTypePropertyDto[]
  ) => {
    let question: iQuestionDtoExtended | undefined = undefined;

    let templates = await fetchTemplates();
    const templateDto = await getTemplateDataItems(templates, incomingQuestionTemplateId);
    console.log("getNewQuestion current template", templateDto);
    question = createQuestionDto(templateDto!);
    question.singleChoiceId = getInteractonTypePropertyIdByName(question, interactionTypeProperties, "SingleChoice")!;
    question.correctResponsePatternId = getInteractonTypePropertyIdByName(
      question,
      interactionTypeProperties,
      "correctResponsesPattern"
    )!;
    question.interactionTypeName = appObject.interactionTypes.find((x) => x.id === question!.interactionTypeId)?.name!;

    syncQuestionWithTemplate(question, templateDto!, interactionTypeProperties);
    let validationDto: ValidationDto = await fetchValidationDto(question);
    setValidationDto(validationDto);

    setQuestion(question);
    return question;
  };

  const onTemplateChanged = async (templateDto: TemplateDto) => {
    let templateDtoCopy = { ...templateDto } as TemplateDto;
    setTemplateDto(templateDtoCopy);

    let newQuestion = getQuestionCopy(question as iQuestionDtoExtended);
    newQuestion.templateId = templateDtoCopy.id;

    if (newQuestion.interactionTypeId !== templateDtoCopy.template!.interactionType) {
      newQuestion.interactionProperties = [];
    }

    // byte av interactionTypeId innebär nytt correctResponsePatternId vilket i sin tur påverkar interactionProperties när vi synkar längre ner (syncQuestionWithTemplate)
    newQuestion.interactionTypeId = templateDtoCopy.template!.interactionType;
    newQuestion.singleChoiceId = getInteractonTypePropertyIdByName(
      newQuestion,
      interactionTypeProperties,
      "SingleChoice"
    )!;
    newQuestion.correctResponsePatternId = getInteractonTypePropertyIdByName(
      newQuestion,
      interactionTypeProperties,
      "correctResponsesPattern"
    )!;

    newQuestion.isModified = true;

    setQuestion(newQuestion);

    syncQuestionWithTemplate(newQuestion, templateDtoCopy!, interactionTypeProperties);
    let validationDto: ValidationDto = await fetchValidationDto(newQuestion);
    setValidationDto(validationDto);
  };

  const syncQuestionWithTemplate = (
    question: iQuestionDtoExtended,
    templateDto: TemplateDto,
    interactionTypeProperties: InteractionTypePropertyDto[]
  ) => {
    syncQuestionTextsWithTemplate(question, templateDto!.template!.textAndMedia!);
    syncQuestionMediaWithTemplate(question, templateDto!.template!.textAndMedia!);
    syncQuestionOptionWithTemplate(question, templateDto!.template!.textAndMedia!);
    syncQuestionInteractionPropertiesWithTemplate(
      question,
      templateDto!.template!.interactionProperties!,
      interactionTypeProperties!
    );
    syncSequencingValuesForAllTypes(appObject.interactionTypes, question, templateDto!.template!.textAndMedia!);
  };

  useEffect(() => {
    init();
    //document.title = `Q - ${incomingQuestionId === 0 ? "Ny fråga" : incomingQuestionId}`;
  }, []);

  useEffect(() => {
    if (question) {
      const newTitle = `F - ${question.id === 0 ? "Ny fråga" : question.id}`;
      if (document.title !== newTitle) {
        document.title = newTitle;
      }

      if (infoData && !statusChangedByUser && question.statusId !== StatusType.Draft) {
        let newInfoCopy: iInfo = getInfoCopy(infoData!);
        newInfoCopy.statusId = StatusType.Draft;
        onInfoChanged(newInfoCopy);
      }
    }

    if (props.onQuestionChanged) {
      props.onQuestionChanged(question);
    }
  }, [question]);

  const save = async () => {
    //validate question
    // console.log("calling API save", question);
    setLoading(true);
    let questionId: number = await saveQuestionDto(question!, appObject.userId);

    if (questionId == -1) {
      showSaveStatus(false, "DEBUG - save disabled");
      setLoading(false);
      return;
    }

    if (questionId > 0) {
      loadQuestion(questionId, interactionTypeProperties);
      setLoading(false);
      showSaveStatus(true, "Sparad!");
    } else {
      showSaveStatus(false, "Fel inträffade!");
    }
  };

  const [showPublishDialog, setShowPublishDialog] = useState<boolean>(false);

  const publish = async (newSemanticVersion: string) => {
    // console.log("publish", newSemanticVersion);

    let newQuestion = getQuestionCopy(question!);
    const currentVersion: string | undefined = question!.semanticVersion;
    newQuestion!.semanticVersion = newSemanticVersion;
    let questionId: number | undefined = newQuestion!.id;
    try {
      setLoading(true);
      let publishQuestionResponse: PublishQuestionResponse = await publishQuestion(newQuestion!);
      setPublishQuestionResponse(publishQuestionResponse);
      if (publishQuestionResponse.success) {
        setShowPublishDialog(false);
        showSaveStatus(true, "Publicerad!");

        loadQuestion(questionId!, interactionTypeProperties);
      } else {
        newQuestion!.semanticVersion = currentVersion;
        showSaveStatus(false, "Fel inträffade!");
      }
    } catch (error) {
      newQuestion!.semanticVersion = currentVersion;
      const validationError: PublishValidationError = new PublishValidationError({ id: question!.id, errorType: 0 });
      setPublishQuestionResponse(
        new PublishQuestionResponse({ success: false, publishValidationErrors: [validationError] })
      );
      setQuestion(newQuestion);
      setShowPublishDialog(true);
      showSaveStatus(false, "Fel inträffade!");
    }
    setLoading(false);
  };

  const showSaveStatus = (success: boolean, text: string) => {
    setSaveStatus({ success: success, error: !success, text: text });

    setTimeout(() => {
      setSaveStatus({ success: false, error: false, text: "" });
    }, 3000);
  };

  const cancel = async () => {
    let questionId: number = question ? question.id! : incomingQuestionId;
    let newQuestion = await loadQuestion(questionId, interactionTypeProperties);
    let newInfoCopy = { ...infoData } as iInfo;
    newInfoCopy.selectedItems = getSelectedClassificationIds(newQuestion);

    setInfoData(newInfoCopy);
  };

  const [saveStatus, setSaveStatus] = useState({
    success: false,
    error: false,
    text: "",
  });

  const copyQuestionButtonClick = async (orgQuestion: iQuestionDtoExtended) => {
    const questionId: number = await copyQuestion(orgQuestion, appObject.userId);
    openQuestion(questionId, 0);
  };

  const visibleInfoElements: string[] = [
    KEY_INFO_CUSTOMER,
    KEY_INFO_STATUS,
    KEY_INFO_KNOWLEDGE_LEVEL,
    KEY_INFO_DEPRECATED,
    KEY_INFO_FRAGETYP_CLASSIFICATIONS,
    KEY_INFO_BEHORIGHET_CLASSIFICATIONS,
    KEY_INFO_LEARNING_OBJECTIVES,
    KEY_INFO_COMMENT,
    KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES,
  ];

  const onInfoChanged = (newInfo: iInfo) => {
    let newQuestion: iQuestionDtoExtended = getQuestionCopy(question!);
    // console.log("onInfoChanged newINfo", newInfo);

    updateQuestionClassifications(newQuestion, newInfo.selectedItems);

    if (newQuestion.statusId !== newInfo.statusId) {
      newQuestion.statusId = newInfo.statusId;
      setStatusChangedByUser(true);
    } else if (!statusChangedByUser) {
      newInfo.statusId = StatusType.Draft;
      newQuestion.statusId = StatusType.Draft;
    }

    newQuestion.customerId = newInfo.customerId;
    newQuestion.knowledgeLevel = newInfo.knowledgeLevel;
    newQuestion.deprecated = newInfo.deprecated;
    newQuestion.comment = newInfo.comment;
    newQuestion.isModified = true;

    setQuestion(newQuestion);
    setInfoData(newInfo);
  };

  const updateQuestionClassifications = (newQuestion: iQuestionDtoExtended, selectedItems: iClassificationItem[]) => {
    //const uniqueRootIds = selectedItems.map(item => item.rootId)
    //    .filter((value, index, self) => self.indexOf(value) === index)

    newQuestion.classifications = [];
    selectedItems
      .filter((x) => x.rootId !== configData!.learningObjectives?.rootId)
      .forEach((item: iClassificationItem) => {
        let newClassification = new ClassificationDto();
        newClassification.rootId = item.rootId;
        newClassification.classificationId = item.identifier;
        newQuestion.classifications!.push(newClassification);
      });

    newQuestion.learningObjectiveDtos = [];
    selectedItems
      .filter((x) => x.rootId === configData!.learningObjectives?.rootId)
      .forEach((item: iClassificationItem) => {
        let newLearningObjective = new LearningObjectiveDto();
        newLearningObjective.id = item.identifier;
        newQuestion.learningObjectiveDtos!.push(newLearningObjective);
      });
  };

  const hideSaveCancelButtons = () => {
    return hideMetadata || hideToolsMenu || hideButtons;
  };
  const handleCancelPublishClick = () => {
    setShowPublishDialog(false);
    setPublishQuestionResponse(undefined);
  };

  const configProviderClassification: ConfigProvider = {
    service: Service.question,
    url: appConfig.REACT_APP_QUESTION_API_URL as string,
    key: "classifications",
  };

  const getPublishError = (): string => {
    // OBS! GÖR INGA ÄNDRINGAR HÄR UTAN ATT VARA HELT SÄKER!

    if (!userIsPublisher) {
      //console.log("questionCanBePublished userIsPublisher = false");
      return "Du har inte behörighet att publicera";
    }

    if (question!.isModified) {
      //console.log("questionCanBePublished dirty = true");
      return "Det finns osparade ändringar";
    }

    if (question!.statusId !== StatusType.Final) {
      //console.log("questionCanBePublished statusId !== Final");
      return "Status måste vara slutlig";
    }

    let isValidatedSuccessfully: boolean = validationDto?.success === true;
    if (!isValidatedSuccessfully) {
      //console.log("questionCanBePublished dirisValidatedSuccessfullyty = false");
      return "Frågan har valideringsfel";
    }

    if (question?.publishStatus === PublishStatusType.None) {
      return "";
    } else if (question?.publishStatus === PublishStatusType.Dirty) {
      return "";
    } else if (question?.publishStatus === PublishStatusType.Failure) {
      return "";
    }

    return "Ogiltig publieringsstatus";
  };

  const renderQuestion = () => {
    return (
      <>
        {(!initDone || loading) && LoadingPanel}

        {initDone && question && question!.id! > 0 && (
          <div className="row mt-2 mb-2">
            <div className="col-12">
              <span title="Id" style={{ fontWeight: "bold" }}>
                {question ? question.id : incomingQuestionId}
              </span>
              <span title="publishStatus" className="ms-2" style={{ fontWeight: "normal", fontSize: "10pt" }}>
                {getCurrentPublishStatusText(question.publishStatus)}
              </span>
              <span title="Externt id" style={{ fontWeight: "bold", color: "grey" }} className="ms-2">
                {question.properties?.find((x) => x.key === "ExternalId")?.value}
              </span>
              <Button
                className="ms-2"
                themeColor="primary"
                size="small"
                title="förhandgranska"
                icon="preview"
                onClick={() => openQuestionPreview(question!.identity!)}
              ></Button>
              {appConfig.REACT_APP_USE_QUESTION_COPY === "true" && !hideToolsMenu && (
                <Button
                  className="ms-2"
                  themeColor="primary"
                  size="small"
                  title="kopiera"
                  icon="copy"
                  onClick={() => copyQuestionButtonClick(question!)}
                ></Button>
              )}
              <Button
                className="ms-2"
                themeColor="primary"
                size="small"
                title="Används i.."
                icon="find"
                onClick={() => setShowWhereIsUsedDialog(!showWhereIsUsedDialog)}
              ></Button>
            </div>
          </div>
        )}

        {initDone && (
          <>
            {!hideMetadata && (
              <div className="row m-2">
                <Info
                  title={"Metadata"}
                  infoData={infoData}
                  configuration={configData}
                  configProviderClassification={configProviderClassification}
                  onInfoChanged={onInfoChanged}
                  expanded={incomingQuestionId === 0}
                />
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <TextMediaContainer
                  question={question}
                  templates={templates}
                  onTemplateChanged={onTemplateChanged}
                  setQuestion={setQuestion}
                  templateDataItems={templateDto!.template?.textAndMedia}
                  save={save}
                  cancel={cancel}
                  validationDto={validationDto}
                />
              </div>
            </div>
            <div className="row m-2" style={{ border: "solid 1px lightgrey", borderRadius: "4px" }}>
              <div className="col-12">
                <InteractionTypeProperty
                  data={interactionTypeProperties}
                  templateInteractionProperties={templateDto!.template?.interactionProperties}
                  question={question}
                  setQuestion={setQuestion}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="me-2" style={{ float: "right" }}>
                  {usePublish && (
                    <div style={{ display: "inline" }} title={getPublishError()}>
                      <Button
                        disabled={getPublishError() !== ""}
                        onClick={() => setShowPublishDialog(true)}
                        themeColor={"primary"}
                        size="medium"
                        style={{ width: 100 }}
                      >
                        Publicera
                      </Button>
                    </div>
                  )}

                  {!hideSaveCancelButtons() && (
                    <>
                      {" "}
                      <Button
                        disabled={!question || !question!.isModified}
                        className="m-2"
                        onClick={save}
                        themeColor={"primary"}
                        style={{ width: 100 }}
                        size="medium"
                      >
                        Spara
                      </Button>
                      <Button
                        disabled={!question || !question!.isModified}
                        themeColor={"primary"}
                        style={{ width: 100 }}
                        onClick={cancel}
                        size="medium"
                      >
                        Avbryt
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <NotificationGroup
          style={{
            left: 0,
            bottom: 0,
            alignItems: "flex-start",
            flexWrap: "wrap-reverse",
          }}
        >
          <Fade>
            {saveStatus.error && (
              <Notification
                type={{
                  style: "error",
                  icon: true,
                }}
                closable={true}
                onClose={() => setSaveStatus({ error: false, success: false, text: "" })}
              >
                <span>{saveStatus.text}</span>
              </Notification>
            )}
          </Fade>

          <Fade>
            {saveStatus.success && (
              <Notification
                type={{
                  style: "success",
                  icon: true,
                }}
                closable={true}
                onClose={() => setSaveStatus({ error: false, success: false, text: "" })}
              >
                <span>{saveStatus.text}</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
        {showCustomDialog && <CustomDialog {...showCustomDialog} />}

        {showWhereIsUsedDialog && (
          <WhereIsUsedDialog
            setShowWhereIsUsedDialog={setShowWhereIsUsedDialog}
            whereIsUsedAssessments={whereIsUsedAssessments}
            question={question}
          />
        )}

        {showPublishDialog && (
          <PublishDialog
            loading={loading}
            semanticVersion={question!.semanticVersion}
            publishStatus={question!.publishStatus}
            onOkClick={publish}
            onCancelClick={handleCancelPublishClick}
            publishResponse={publishQuestionResponse}
          />
        )}
      </>
    );
  };

  const renderQuestionWithPanel = () => {
    return (
      <ExpansionPanel
        style={{ borderRadius: "5px", marginTop: "2px", marginBottom: "2px" }}
        title={props.questionPanelTitle}
        expanded={expanded}
        tabIndex={0}
        key={1}
        onAction={(event: ExpansionPanelActionEvent) => {
          setExpanded(!event.expanded);
        }}
      >
        <Reveal>
          {expanded && (
            <ExpansionPanelContent onKeyDown={(e) => e.stopPropagation()}>{renderQuestion()}</ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
    );
  };

  // const renderTest = () => {
  //   return <div>Test</div>;
  // };

  // const renderTest2 = () => {
  //   return renderTest();
  // };

  return <>{showQuestionPanel ? renderQuestionWithPanel() : renderQuestion()}</>;
};

export default NewQuestion;
