import * as React from "react";
import LearningObjectEditor, { ISaveData } from "./LearningObjectEditor";
import {
  Client,
  Component,
  ContainerClassificationDto,
  ContainerLearningObjectiveDto,
  ContainerPublishRequest,
  ContainerSubTypeDto,
  GetLearningObjectContainerResponse,
  LearningObjectStructure,
  PublishContainerResponse,
  PublishValidationError,
  SaveContainerRequest,
  WhereUsedDto,
} from "../../../api/Client";
import { appConfig } from "../../../appConfig";
import { IMetaDataFromDocument } from "./HtmlFilter";
import { useSearchParams } from "react-router-dom";
import { TextArea, TextBox } from "@progress/kendo-react-inputs";
import Info from "../../common/Info";
import { iInfo } from "../../../interfaces/iInfo";
import {
  CONTAINER_SUB_TYPE_INLEDNING,
  CONTAINER_SUB_TYPE_SAMMANFATTNING,
  CONTAINER_TYPE_LEARNING_OBJECT,
  // AMNE_CLASSIFICATION_ID,
  // BEHORERHET_CLASSIFICATION_ID,
  // BEHORERHET_FM_CLASSIFICATION_ID,
  // FRAGESTYP_CLASSIFICATION_ID,
  KEY_INFO_BEHORIGHET_CLASSIFICATIONS,
  KEY_INFO_COMMENT,
  KEY_INFO_CUSTOMER,
  KEY_INFO_DEPRECATED,
  KEY_INFO_EXTERNAL_ID,
  KEY_INFO_FRAGETYP_CLASSIFICATIONS,
  KEY_INFO_LEARNING_OBJECTIVES,
  KEY_INFO_STATUS,
  KEY_INFO_SUBTYPE,
  KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES,
  LEARNING_OBJECTIVES_ROOT_ID,
  LEARNING_OBJECT_FINAL_STATUS,
  LEARNING_OBJECT_NEW_DEFAULT_ORGANISATION,
  LEARNING_OBJECT_NEW_DEFAULT_STATUS,
  LEARNING_OBJECT_NEW_DEFAULT_TYPE,
} from "../../common/Constants";

import {
  iClassificationItem,
  iConfiguration,
  iConfigurationClassification,
} from "../../common/classifications/interfaces";
import { useAppContext } from "../../common/AppContext";
import { SaveNotificationStatus } from "../../common/SaveNotificationStatus";
import { useEffect, useState } from "react";
import { getCurrentPublishStatusText, getInfoCopy } from "../../common/helperFunctions";
import { copyContainer, fetchConfiguration } from "../../../api/learnable_lcms/apiFunctions";
import { Button } from "@progress/kendo-react-buttons";
import { openLearningObject, openLearningObjectPreview } from "../../question/edit/functions/helperFunctions";
import { getCurrentDateTime } from "../utilities";
import LoadingPanel from "../../common/LoadingPanel";
import { iQuestionDtoExtended } from "../../../interfaces/iQuestionDtoExtended";
import {
  configProviderClassificationLcms,
  getLearningObjectives,
  getWhereUsedContainer,
} from "../../common/classifications/helperFunctions";
import { StatusType } from "../../common/Enums";
import { getSelectedClassificationIds } from "./functions/containerDtoFunctions";

interface IIdInfo {
  id: number;
  isNew: boolean;
}

interface IMetaData {
  title: string;
  description: string;
  learningObjective: string; //TODO: This should be replaced by a connection to the learning objective
}

const LearningObject = () => {
  const [containerSubTypesLearningObject, setContainerSubTypesLearningObject] = useState<
    ContainerSubTypeDto[] | undefined
  >([]);

  const [editComponents, setEditComponents] = React.useState<Component[] | undefined>(undefined);
  const [metaData, setMetaData] = React.useState<IMetaData>({ title: "", description: "", learningObjective: "" });
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const incomingId: number = searchParams.get("id") ? parseInt(searchParams.get("id") as string) : 0;
  const [recordId, setRecordId] = React.useState<IIdInfo>({ id: incomingId, isNew: incomingId === 0 });
  const incomingContentId: string = searchParams.get("contentId") || "";
  const learningObjectTypeId: string = searchParams.get("learningObjectTypeId") || "";
  const isAutoPublish: boolean = learningObjectTypeId !== "";

  // incoming from LearningStructure
  const defaultOrganisation: string = searchParams.get("o") ?? LEARNING_OBJECT_NEW_DEFAULT_ORGANISATION;
  const incomingReadPracticeTypeGuid: string = searchParams.get("t") ?? "";
  const incomingBehClassificationGuids: string[] = searchParams.get("e")?.split(",") ?? [];
  const incomingLearningObjectGuids: string[] = searchParams.get("l")?.split(",") ?? [];

  const appObject = useAppContext();
  const [configData, setConfigData] = useState<iConfiguration | undefined>(undefined);

  // const [classificationContainer, setClassificationContainer] = React.useState<ClassificationContainer | undefined>(
  //   undefined
  // );
  const [selectedClassifications, setSelectedClassifications] = useState<iClassificationItem[]>([]);
  const [initDone, setInitDone] = React.useState<boolean>(false);
  const [infoData, setInfoData] = React.useState<iInfo | undefined>(undefined);
  const [learningObjectContainerResponse, setLearningObjectContainerResponse] = React.useState<
    GetLearningObjectContainerResponse | undefined
  >(undefined);
  const [publishContainerResponse, setPublishContainerResponse] = useState<PublishContainerResponse | undefined>(
    undefined
  );
  // const [whereUsed, setWhereUsed] = useState<WhereUsedDto[] | undefined>(undefined);
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [notificationStatus, setNotificationStatus] = useState({
    success: false,
    error: false,
    text: "",
  });

  enum Action {
    ALIVE,
    UPDATED,
    CANCELLED,
    FAILED,
  }

  const [messageFromParent, setMessageFromParent] = useState("");

  window.addEventListener("load", () => {
    window.opener.postMessage("ready", appConfig.REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL);
  });

  // Set up message listener to receive messages from the main window
  useEffect(() => {
    const handleMessage = (event: any) => {
      // console.log("Message received from main window:", event.origin, event.data);

      // Check if the message is from the expected origin
      if (event.origin === appConfig.REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL) {
        // Update this to the main app's origin
        setMessageFromParent(event.data);
        console.log("Message received from main window:", event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // window.addEventListener("message", (event) => {
  //   console.log("Message received from main window:", event.origin, event.data);

  //   // Check if the message is from the expected origin
  //   if (event.origin === appConfig.REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL) {
  //     // Update this to the main app's origin
  //     setMessageFromParent(event.data);
  //     console.log("Message received from main window:", event.data);
  //   }
  // });

  // Function to send a message back to the main window
  const sendMessageToParent = (action: Action, contentId: string = "", versionId = "") => {
    if (isAutoPublish && window.opener) {
      window.opener.postMessage(
        { action: action, contentId: contentId, versionId: versionId },
        appConfig.REACT_APP_LEARNINGSTRUCTURE_CLIENT_URL
      );
    }
  };

  sendMessageToParent(Action.ALIVE);

  const showNotification = (success: boolean, text: string) => {
    setNotificationStatus({ success: success, error: !success, text: text });

    setTimeout(() => {
      setNotificationStatus({ success: false, error: false, text: "" });
    }, 3000);
  };

  const visibleInfoElements: string[] = [
    KEY_INFO_CUSTOMER,
    KEY_INFO_STATUS,
    KEY_INFO_FRAGETYP_CLASSIFICATIONS,
    KEY_INFO_BEHORIGHET_CLASSIFICATIONS,
    KEY_INFO_LEARNING_OBJECTIVES,
    KEY_INFO_DEPRECATED,
    KEY_INFO_SUBTYPE,
    KEY_INFO_COMMENT,
    KEY_INFO_WHERE_USED_LEARNINGSTRUCTURES,
    KEY_INFO_EXTERNAL_ID,
  ];

  const [learningObjectivesAll, setLearningObjectivesAll] = useState<any[]>([]);

  //getCurrentPublishStatusText(learningObjectContainerResponse.learningObjectContainer?.publishStatus)
  const [publishStatusText, setPublishStatusText] = useState<string>("");

  const init = async () => {
    setInitDone(false);
    setStatusChangedByUser(false);

    let newRecordId: IIdInfo = { ...recordId };
    if (incomingId === 0 && isAutoPublish) {
      if (incomingContentId !== "") {
        // Update existing learning object
        const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
        const client = new Client(apiUrl);
        const containerId: number = await client.publishGetContainerId(incomingContentId, "1");
        newRecordId = { id: containerId, isNew: false };
      }
    }
    setRecordId(newRecordId);
    setExpanded(newRecordId.isNew);
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    const subTypes = await client.containerGetContainerSubTypes(CONTAINER_TYPE_LEARNING_OBJECT);
    setContainerSubTypesLearningObject(
      subTypes.filter((x) => x.id != CONTAINER_SUB_TYPE_SAMMANFATTNING && x.id != CONTAINER_SUB_TYPE_INLEDNING)
    );

    let selectedItems: iClassificationItem[] = [];
    let learningObjectContainerResponse: GetLearningObjectContainerResponse | undefined;
    let whereUsedItems: WhereUsedDto[] | undefined = undefined;
    setLearningObjectivesAll(await getLearningObjectives());
    let newMetaData: IMetaData = { ...metaData };
    if (newRecordId.isNew) {
      // Create new empty learning object
      setEditComponents([]);
    } else {
      try {
        learningObjectContainerResponse = await client.learningObjectGet(newRecordId.id!);
        setPublishStatusText(
          getCurrentPublishStatusText(learningObjectContainerResponse.learningObjectContainer?.publishStatus)
        );
        setLearningObjectContainerResponse(learningObjectContainerResponse);
        // console.log("learningObjectContainerResponse", learningObjectContainerResponse);
        let selectedClassificationItems =
          learningObjectContainerResponse.learningObjectContainer!.containerClassificationDtos!.map((x) => {
            return { identifier: x.classificationId, rootId: x.classificationRoot } as iClassificationItem;
          });
        let selectedLearningObjectiveItems =
          learningObjectContainerResponse.learningObjectContainer!.containerLearningObjectiveDtos!.map((x) => {
            return { identifier: x.learningObjectiveId, rootId: LEARNING_OBJECTIVES_ROOT_ID } as iClassificationItem;
          });
        selectedItems = selectedClassificationItems.concat(selectedLearningObjectiveItems);
        // console.log("selectedClassification", selectedItems);

        selectedItems = getSelectedClassificationIds(learningObjectContainerResponse.learningObjectContainer!);
        setSelectedClassifications(selectedItems);
        console.log("selectedItems", selectedItems);

        let reply: LearningObjectStructure = await client.editorComponentGetComponent(
          newRecordId.id,
          learningObjectContainerResponse.learningObjectContainer!.customerDto.id
        );
        newMetaData.title = reply.title || "";
        newMetaData.description = reply.description || "";

        whereUsedItems = await getWhereUsedContainer(learningObjectContainerResponse.learningObjectContainer!);

        setEditComponents(reply.components);
      } catch (error) {
        showNotification(false, `Lärobjekt ${recordId} kunde inte läsas`);
        console.error("Error reading learning object", error);
      }
    }

    let configurationData: iConfiguration = await fetchConfiguration("Classifications");

    const newClassification: iConfigurationClassification = {
      rootIds: [LEARNING_OBJECTIVES_ROOT_ID],
      title: "Lärandemål",
      showSelected: true,
      checkChildren: false,
      key: LEARNING_OBJECTIVES_ROOT_ID,
    };
    configurationData.classifications!.push(newClassification);
    configurationData.learningObjectives = {
      rootId: LEARNING_OBJECTIVES_ROOT_ID,
      title: "Lärandemål",
      showSelected: true,
    };
    setConfigData(configurationData);

    console.log(
      "customer",
      // appObject.customers.find((x) => (x.name = LEARNING_OBJECT_NEW_DEFAULT_ORGANISATION)),
      appObject.customers
    );

    if (incomingId === 0) {
      incomingReadPracticeTypeGuid && appendSelectedItems(selectedItems, [incomingReadPracticeTypeGuid]);
      incomingBehClassificationGuids.length && appendSelectedItems(selectedItems, incomingBehClassificationGuids);
      incomingLearningObjectGuids.length && appendSelectedItems(selectedItems, incomingLearningObjectGuids);
    }

    let info: iInfo = {
      customerId: learningObjectContainerResponse
        ? learningObjectContainerResponse!.learningObjectContainer!.customerDto.id!
        : defaultOrganisation,
      statusId: isAutoPublish
        ? LEARNING_OBJECT_FINAL_STATUS
        : learningObjectContainerResponse
        ? learningObjectContainerResponse!.learningObjectContainer!.lifeCycleStatus!
        : LEARNING_OBJECT_NEW_DEFAULT_STATUS,
      knowledgeLevel: 0,
      deprecated: learningObjectContainerResponse
        ? learningObjectContainerResponse!.learningObjectContainer!.deprecated!
        : false,
      selectedItems: selectedItems,
      visibleElements: visibleInfoElements,
      subTypeId: learningObjectContainerResponse
        ? learningObjectContainerResponse!.learningObjectContainer?.containerSubType!
        : LEARNING_OBJECT_NEW_DEFAULT_TYPE,
      comment: learningObjectContainerResponse
        ? learningObjectContainerResponse!.learningObjectContainer?.comment!
        : "",
      whereUsed: whereUsedItems,
      externalId: learningObjectContainerResponse?.learningObjectContainer?.friendlyId ?? "",
    };

    // Set incoming meta data
    const title: string = searchParams.get("title") || "";
    if (title) newMetaData.title = title;
    //TODO: Lägg till mer metadata som behövs för inledning och sammanfattning.
    setMetaData(newMetaData);

    if (learningObjectTypeId !== "") info.subTypeId = parseInt(learningObjectTypeId);
    //TODO: Lägg till mer info data som behövs för inledning och sammanfattning.

    setInfoData(info);
    setInitDone(true);
  };

  const appendSelectedItems = (selectedItems: iClassificationItem[], guids: string[]) => {
    guids.forEach((guid) => {
      let found = selectedItems.find((x) => x.identifier === guid);
      if (!found) {
        var newSelected: iClassificationItem = {
          identifier: guid,
        } as iClassificationItem;
        selectedItems.push(newSelected);
      }
    });
  };

  const [reload, setReload] = useState<boolean>(false);
  const onReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    init();
  }, [reload]);

  React.useEffect(() => {
    if (recordId) {
      const newTitle = `L - ${recordId.id === 0 ? "Nytt lärobjekt" : recordId.id}`;
      if (document.title !== newTitle) {
        document.title = newTitle;
      }
    }
  }, [recordId]);

  React.useEffect(() => {
    if (!isAutoPublish && dirty && !statusChangedByUser && infoData!.statusId !== StatusType.Draft) {
      let newInfoCopy: iInfo = getInfoCopy(infoData!);
      newInfoCopy.statusId = StatusType.Draft;
      onInfoChanged(newInfoCopy);
    }
  }, [dirty]);

  const createSaveContainerRequest = (containerId: number): SaveContainerRequest => {
    let saveContainerRequest: SaveContainerRequest = new SaveContainerRequest();
    //saveContainerRequest.learningObjectStructure = learningObjectStructure;
    saveContainerRequest.customerId = infoData!.customerId;
    saveContainerRequest.lifeCycleStatus = infoData!.statusId;
    saveContainerRequest.deprecated = infoData!.deprecated;
    saveContainerRequest.comment = infoData!.comment;
    saveContainerRequest.containerClassifications = [];
    saveContainerRequest.containerLearningObjectives = [];
    // classificationContainer?.selected
    //   .filter((x) => x.rootId !== LEARNING_OBJECTIVES_ROOT_ID)
    //   .forEach((x) => {
    //     saveContainerRequest.containerClassifications?.push(
    //       new ContainerClassificationDto({ classificationId: x.identifier, containerId: containerId })
    //     );
    //   });

    // classificationContainer?.selected
    //   .filter((x) => x.rootId === LEARNING_OBJECTIVES_ROOT_ID)
    //   .forEach((x) => {
    //     saveContainerRequest.containerLearningObjectives?.push(
    //       new ContainerLearningObjectiveDto({ learningObjectiveId: x.identifier, containerId: containerId })
    //     );
    //   });
    selectedClassifications
      .filter((x) => x.rootId === LEARNING_OBJECTIVES_ROOT_ID)
      .forEach((x) => {
        saveContainerRequest.containerLearningObjectives?.push(
          new ContainerLearningObjectiveDto({ learningObjectiveId: x.identifier, containerId: containerId })
        );
      });
    selectedClassifications
      .filter((x) => x.rootId !== LEARNING_OBJECTIVES_ROOT_ID)
      .forEach((x) => {
        saveContainerRequest.containerClassifications?.push(
          new ContainerClassificationDto({
            classificationRoot: x.rootId,
            classificationId: x.identifier,
            containerId: containerId,
          })
        );
      });
    console.log("saveContainerRequest", saveContainerRequest);
    return saveContainerRequest;
  };

  const createLearningObjectStructure = (data: ISaveData): LearningObjectStructure => {
    const learningObjectStructure = new LearningObjectStructure({
      id: recordId!.id,
      title: metaData.title,
      description: metaData.description,
      subType: infoData!.subTypeId,
      components: data.components,
    });

    return learningObjectStructure;
  };

  const onCancel = () => {
    if (!isAutoPublish) init();
    sendMessageToParent(Action.CANCELLED);
  };

  const onSave = async (data: ISaveData) => {
    // Create LearningObjectStructure
    let id = recordId!.id;
    let documentSaved: boolean = false;
    const learningObjectStructure = createLearningObjectStructure(data);

    // This console entry should be kept for debugging purposes
    console.info(`>>> ${getCurrentDateTime()}: learningObjectId="${id}" saving`, learningObjectStructure);

    // Call API to save data
    const client = new Client(appConfig.REACT_APP_LCMS_API_URL);
    if (recordId!.id === 0) {
      try {
        let newId: number = await client.editorComponentCreateComponent(
          infoData?.statusId,
          infoData?.customerId,
          learningObjectStructure
        );
        let success: boolean = newId > 0;

        if (success) {
          documentSaved = true;
          let saveContainerRequest: SaveContainerRequest = createSaveContainerRequest(newId);
          success = await client.learningObjectPut(newId, saveContainerRequest);

          await client.settingSetLatestLearningObjects(newId, appObject.userId);
        }

        if (success) {
          let learningObjectContainerResponse = await client.learningObjectGet(newId);

          if (isAutoPublish) {
            await onAutoPublish("1.0.0", newId);
          }

          showNotification(true, "Nytt lärobjekt har sparats");

          setLearningObjectContainerResponse(learningObjectContainerResponse);
          setPublishStatusText(
            getCurrentPublishStatusText(learningObjectContainerResponse.learningObjectContainer?.publishStatus)
          );
        } else {
          showNotification(false, "Lärobjektet kunde inte sparas");
          console.error("Error creating new learning object. Replied false.");
        }
        id = newId;
        setEditComponents(data.components);
        setRecordId({ id: newId, isNew: !success });
      } catch (error) {
        showNotification(false, "Lärobjektet kunde inte sparas");
        console.error("Error saving new learning object", error);
      }
    } else {
      try {
        // denna rad ska tas bort och hanteras i client.learningObjectPut längre ner
        let success = await client.editorComponentUpdateComponent(
          recordId!.id,
          infoData?.statusId,
          infoData?.customerId,
          learningObjectStructure
        );

        if (success) {
          documentSaved = true;
          let saveContainerRequest: SaveContainerRequest = createSaveContainerRequest(recordId!.id);
          success = await client.learningObjectPut(recordId!.id, saveContainerRequest);
          await client.settingSetLatestLearningObjects(recordId!.id, appObject.userId);
        }

        if (success) {
          let learningObjectContainerResponse = await client.learningObjectGet(recordId!.id);
          if (isAutoPublish) {
            let currentVersion: string | undefined =
              learningObjectContainerResponse!.learningObjectContainer!.semanticVersion ?? undefined;

            let newCurrentVersion = currentVersion
              ? `${parseInt(currentVersion!.split(".")[0])}.${parseInt(currentVersion!.split(".")[1])}.${
                  parseInt(currentVersion!.split(".")[2]) + 1
                }`
              : "1.0.0";

            await onAutoPublish(newCurrentVersion, recordId!.id);
          }

          //showNotification(true, "Lärobjektet har uppdaterats");
          showNotification(true, "Nytt lärobjekt har uppdaterats");
          setLearningObjectContainerResponse(learningObjectContainerResponse);
          setPublishStatusText(
            getCurrentPublishStatusText(learningObjectContainerResponse.learningObjectContainer?.publishStatus)
          );
        } else {
          showNotification(false, "Lärobjektet kunde inte uppdateras");
          console.error("Error updating learning object. Replied false.");
        }
      } catch (error) {
        showNotification(false, "Lärobjektet kunde inte uppdateras");
        console.error("Error updating learning object", error);
      }
    }

    if (documentSaved) {
      // Read document again so user can  see that everything was saved correctly
      const lj = await client.learningObjectGet(id);
      let reply: LearningObjectStructure = await client.editorComponentGetComponent(
        id,
        lj.learningObjectContainer!.customerDto.id
      );
      setEditComponents(reply.components);
    } else {
      // This is a workaround to make the editor's working-icon stop spinning when document wasn't saved.
      setEditComponents(data.components);
    }
  };

  const onAutoPublish = async (newSemanticVersion: string, containerId: number) => {
    // let newSemanticVersion: string = `${currentVersion.split(".")[0]}.${currentVersion.split(".")[1]}.${
    //   currentVersion.split(".")[2] + 1
    // }`;

    let publishContainerResponse: PublishContainerResponse = await onPublish(newSemanticVersion, containerId);

    if (publishContainerResponse.success) {
      sendMessageToParent(Action.UPDATED, publishContainerResponse.contentId, publishContainerResponse.versionId);
    } else {
      showNotification(false, "Lärobjektet kunde inte publiceras");
      console.error("Error publishing learning object. Replied false");
      sendMessageToParent(Action.FAILED, "", "");
    }
  };

  const onPublish = async (newSemanticVersion: string, containerId: number): Promise<PublishContainerResponse> => {
    const apiUrl: string = appConfig.REACT_APP_LCMS_API_URL as string;
    const client = new Client(apiUrl);
    let success: boolean = false;
    let request = new ContainerPublishRequest();
    request.containerId = containerId;
    request.semanticVersion = newSemanticVersion;
    request.owner = infoData!.customerId;

    let response: PublishContainerResponse;
    try {
      response = await client.publishPublish("1", request);

      if (response.success) {
        setPublishStatusText(getCurrentPublishStatusText(3));
        success = true;
        showNotification(true, "Lärobjektet har publicerats");
      } else {
        // setPublishContainerResponse(response);
        showNotification(false, "Lärobjektet kunde inte publiceras");
        console.error("Error publishing learning object. Replied false.", response);
      }
    } catch (error) {
      const validationError: PublishValidationError = new PublishValidationError({ id: recordId?.id, errorType: 0 });
      response = new PublishContainerResponse({ success: false, publishValidationErrors: [validationError] });

      setPublishStatusText(getCurrentPublishStatusText(4));
      showNotification(false, "Fel inträffade!");
    }

    setPublishContainerResponse(response);
    return response;
  };

  const onPastedMetaData = (fromDoc: IMetaDataFromDocument) => {
    //TODO: Lookup and set the learning objective connection
    setLearningObjectiveSelected(fromDoc.learningObjective);
    setMetaData({ ...metaData, title: fromDoc.title, learningObjective: fromDoc.learningObjective });
  };

  const newMetaData = React.useCallback(
    (newMetaData: IMetaData) => {
      setMetaData(newMetaData);
      setDirty(true);
    },
    [metaData]
  );

  const [statusChangedByUser, setStatusChangedByUser] = useState<boolean>(false);
  const onInfoChanged = (newInfo: iInfo) => {
    //console.log("NewQuestion_onInfoChanged", newInfo);

    if (infoData!.statusId !== newInfo.statusId) {
      setStatusChangedByUser(true);
    } else if (!statusChangedByUser) {
      newInfo.statusId = StatusType.Draft;
    }

    setDirty(true);

    setSelectedClassifications(newInfo.selectedItems);
    setInfoData(newInfo);
  };

  const setLearningObjectiveSelected = (name: string): string | undefined => {
    let learningObjective = learningObjectivesAll.find((x: any) => x.name?.toLowerCase() === name.toLowerCase());
    if (!learningObjective) {
      return;
    }
    let found = selectedClassifications.find((x) => x.identifier === learningObjective.identifier);
    if (!found) {
      const selectedClassificationsCopy = [...selectedClassifications];
      const newSelected: iClassificationItem = {
        identifier: learningObjective.identifier,
        rootId: LEARNING_OBJECTIVES_ROOT_ID,
      } as iClassificationItem;
      selectedClassificationsCopy.push(newSelected);
      setSelectedClassifications(selectedClassificationsCopy);
      setInfoData({ ...infoData!, selectedItems: selectedClassificationsCopy });
    }
  };

  const getEditorHeight = () => {
    return expanded ? "47vh" : "80vh";
  };

  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);

  const [selectedQuestion, setSelectedQuestion] = useState<any | undefined>(undefined);
  const onSelectQuestion = (question: iQuestionDtoExtended) => {
    setSelectedQuestion(question);
    onClose();
  };

  const onClose = () => {
    setVisibleDialog(false);
  };

  const copyLearningObjectButtonClick = async (learningObjectId: number) => {
    const newContainerId: number | undefined = await copyContainer(learningObjectId);
    let success: boolean = newContainerId > 0;
    if (success) {
      openLearningObject(newContainerId);
    } else {
      showNotification(false, "Lärobjektet kunde inte kopieras");
    }
  };

  return (
    <>
      {!initDone && LoadingPanel}
      {initDone && !editComponents && <p>Error!</p>}

      {!isAutoPublish && initDone && editComponents && learningObjectContainerResponse && (
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <span title="Id" style={{ fontWeight: "bold" }}>
              {learningObjectContainerResponse?.learningObjectContainer?.id}
            </span>
            <span className="ms-2" style={{ fontWeight: "normal", fontSize: "10pt" }}>
              {publishStatusText}
            </span>
            <Button
              className="ms-2"
              themeColor="primary"
              size="small"
              title="förhandgranska"
              icon="preview"
              hidden={recordId?.isNew}
              onClick={() => openLearningObjectPreview(recordId!.id)}
            ></Button>

            <Button
              className="ms-2"
              themeColor="primary"
              size="small"
              title="kopiera"
              icon="copy"
              onClick={() => copyLearningObjectButtonClick(recordId!.id)}
            ></Button>
          </div>
        </div>
      )}

      {initDone && editComponents && (
        <div className="row m-2">
          {!isAutoPublish && (
            <div>
              Titel
              <TextBox
                id={"title"}
                value={metaData.title}
                onChange={(event) => newMetaData({ ...metaData, title: event.value!.toString() })}
              />
              Beskrivning
              <TextArea
                id={"title"}
                value={metaData.description}
                onChange={(event) => newMetaData({ ...metaData, description: event.value!.toString() })}
              />
              <hr />
              {initDone && (
                <Info
                  title="Metadata"
                  infoData={infoData}
                  onInfoChanged={onInfoChanged}
                  configProviderClassification={configProviderClassificationLcms}
                  containerSubTypes={containerSubTypesLearningObject}
                  configuration={configData}
                  expanded={recordId!.isNew}
                  contentId={learningObjectContainerResponse?.learningObjectContainer?.contentId}
                />
              )}
              <hr />
            </div>
          )}

          <div className="mt-2">
            <LearningObjectEditor
              reload={onReload}
              hideSaveCancelButtons={false}
              hideInteractionButton={isAutoPublish}
              hideCarouselButton={isAutoPublish}
              hidePublishButton={isAutoPublish}
              cancelAlwaysEnabled={isAutoPublish}
              height={getEditorHeight()}
              key={recordId?.id}
              learningObjectId={recordId?.id}
              currentSemanticVersion={learningObjectContainerResponse?.learningObjectContainer?.semanticVersion}
              publishStatus={learningObjectContainerResponse?.learningObjectContainer?.publishStatus}
              info={infoData}
              components={editComponents}
              dirty={dirty}
              setDirty={setDirty}
              publishContainerResponse={publishContainerResponse}
              onPastedMetaData={onPastedMetaData}
              onSave={onSave}
              onPublish={onPublish}
              onCancel={onCancel}
              onCancelPublish={() => setPublishContainerResponse(undefined)}
            />
          </div>
        </div>
      )}

      <SaveNotificationStatus saveStatus={notificationStatus} setSaveStatus={setNotificationStatus} />
    </>
  );
};

export default LearningObject;
